import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Artist from "../components/artist"
import Painting from "../components/painting"
import loadable from "@loadable/component"

const PicassoComponent = loadable(() => import("../components/picassoComponent"))

const Picasso = ({ data }) => {
  return (

    <Layout>
      <SEO
        title="Pablo Picasso"
        description="Pablo Picasso life and works"
        image="/logo.png"
        pathname="/picasso"

      />

      <Artist
        bubble2={{ "--bubble2": "#265904" }}
        name={"Pablo Picasso"}
        dates={"(1881-1973)"}
        descrip={"Pablo Picasso is remembered as an outlaw genius who liked to live large. He once said, “When I was a child my mother said to me, 'If you become a soldier, you'll be a general. If you become a monk, you'll be the pope. ' Instead, I became a painter and wound up as Picasso.”"}
      />

      <Painting
        leftPanel={{ backgroundColor: "#687c5b" }}
        h1Title={"Self Portrait"}
        sectionDescription={"There are 23 words in Picasso’s name: Pablo Diego José Francisco de Paula Juan Nepomuceno María de los Remedios Cipriano de la Santísima Trinidad Martyr Patricio Clito Ruíz y Picasso.  The name Picasso was actually his mother’s last name.  His parents thought it sounded better than Ruiz."}
        paintingFixed={data.selfportrait.childImageSharp.fixed}
        alt={"Self Portrait"}
        rtPanel={{ backgroundColor: "#c39313" }}
        // Mobile
        paintingFluid={data.selfportraitmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#687c5b" }}
      />
      <Painting
        leftPanel={{ backgroundColor: "#5f838c" }}
        h1Title={"Las Meninas"}
        sectionDescription={"He completed his first painting by age 9.  At 13 he was able to complete a difficult entrance exam to get into the School of Fine Arts in Barcelona.  Although Picasso was brilliant, he was considered a bad student because he hated being told what to do and was often in detention.  Because he was able to take a sketchpad with him to, he liked detention because he could sketch non-stop. "}
        paintingFixed={data.lasmeninas.childImageSharp.fixed}
        alt={"Las Meninas"}
        rtPanel={{ backgroundColor: "#a7adb7" }}
        // Mobile
        paintingFluid={data.lasmeninas.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#5f838c" }}
      />
        <Painting
          leftPanel={{ backgroundColor: "#85abc5" }}
          h1Title={"The Kiss"}
          sectionDescription={"Picasso’s father was a talented artist and taught at the Barcelona School of Fine Arts.  When Picasso turned 15, his father said he was so impressed with his talent that he was giving up painting because his son had surpassed him."}
          paintingFixed={data.thekiss.childImageSharp.fixed}
          alt={"The Kiss"}
          rtPanel={{ backgroundColor: "#e2dfcb" }}
          // Mobile
          paintingFluid={data.thekiss.childImageSharp.fluid}
          mobileBackground={{ backgroundColor: "#85abc5" }}
        />
        <Painting
      leftPanel={{ backgroundColor: "#55758f" }}
      h1Title={"Les Demoiselles d’Avignon"}
      sectionDescription={"In his early twenties, Picasso moved to Paris and became friends with Paul Cezanne, Paul Gaugin and Henri Matisse.  These painters pushed the limits of what art critics found acceptable and Picasso was inspired by this.  They showed him a variety of non-European art forms, viewpoints, and ideas.  Picasso especially liked African art and it inspired him to create paintings, like Les Demoiselles d’Avignon. "}
      paintingFixed={data.demoisellesdavignon.childImageSharp.fixed}
      alt={"Les Demoiselles d’Avignon"}
      rtPanel={{ backgroundColor: "#fbb9a1" }}
      // Mobile
      paintingFluid={data.demoisellesdavignonmobile.childImageSharp.fluid}
      mobileBackground={{ backgroundColor: "#55758f" }}
    />
    <Painting
      leftPanel={{ backgroundColor: "#19517b" }}
      h1Title={"Guernica"}
      sectionDescription={"The Nazis would not let Picasso display his paintings in Germany.  His most famous piece, Guernica represents the Nazi’s devastating bombing and the suffering it caused during the Spanish Civil War. A Nazi officer once pointed to Guernica and asked Picasso, “Did you do this?” Picasso replied “No, you did!”"}
      paintingFixed={data.guernica.childImageSharp.fixed}
      alt={"Guernica"}
      rtPanel={{ backgroundColor: "#131313" }}
      // Mobile
      paintingFluid={data.guernicamobile.childImageSharp.fluid}
      mobileBackground={{ backgroundColor: "#19517b" }}
    />
    <Painting
      leftPanel={{ backgroundColor: "#96b6b7" }}
      h1Title={"The Old Guitarist"}
      sectionDescription={"Picasso created 147,800 pieces of art: 13,500 paintings, 100,000 prints and engravings, 300 sculptures and ceramics and 34,000 illustrations. In addition to co-founding Cubism, he invented the collage as an art form.  He was also a poet and once said he would become most famous for his poetry.  "}
      paintingFixed={data.theoldguitarist.childImageSharp.fixed}
      alt={"The Old Guitarist"}
      rtPanel={{ backgroundColor: "#6c8190" }}
      // Mobile
      paintingFluid={data.theoldguitaristmobile.childImageSharp.fluid}
      mobileBackground={{ backgroundColor: "#96b6b7" }}
    />
<PicassoComponent/>
    </Layout>
  )
}

export default Picasso

export const query = graphql`
  {
 selfportrait: file(relativePath: { eq: "paintings/picasso/selfportrait.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     selfportraitmobile: file(relativePath: { eq: "paintings/picasso/selfportraitmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
     lasmeninas: file(relativePath: { eq: "paintings/picasso/lasmeninas.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     lasmeninas: file(relativePath: { eq: "paintings/picasso/lasmeninas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }  
    thekiss: file(relativePath: { eq: "paintings/picasso/thekiss.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     thekiss: file(relativePath: { eq: "paintings/picasso/thekiss.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }  
    demoisellesdavignon: file(relativePath: { eq: "paintings/picasso/demoisellesdavignon.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     demoisellesdavignonmobile: file(relativePath: { eq: "paintings/picasso/demoisellesdavignonmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }  guernica: file(relativePath: { eq: "paintings/picasso/guernica.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     guernicamobile: file(relativePath: { eq: "paintings/picasso/guernicamobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }  
    theoldguitarist: file(relativePath: { eq: "paintings/picasso/theoldguitarist.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     theoldguitaristmobile: file(relativePath: { eq: "paintings/picasso/theoldguitaristmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    }
    `